@import 'tailwindcss';

@theme {
  --font-*: initial;
  --font-jetbrains: JetBrains Mono, monospace;
}

@utility container {
  margin-inline: auto;
  padding-inline: 2rem;
}

@layer base {
    @font-face {
        font-family: 'JetBrains Mono';
        font-style: normal;
        font-weight: 400;
        font-display: swap;
        src: url(JetBrainsMono-Regular.woff2) format('woff2');
    }
}
